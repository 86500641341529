body {
  background: #ffd2da;
  background-repeat: no-repeat;
}

.container {
  padding-left: 0%;
  padding-right: 0%;
  align-self: left;
  align-items: left;
}

.navbar {
  width: 100%;
  display: contents;
  align-self: center;
  align-items: center;
}

.header {
  color:rgb(0,0,0);
  text-align: center;
  font-size: 35px;

}

.navbar nav {
  flex: 1;
  text-align: center;
}

.hoverbutton {
  list-style: none;
  display: inline-block;
  inline-size:auto;
  background-color: pink;
  margin: 6px;
  border-radius: 10%;
  padding: 25px;
}

.pageTitle {
  margin-top: 60px;
  font-size: 25px;
  text-align: center;
  flex:1;
}

.hoverbutton a {
  text-decoration: none;
  color: rgb(33, 9, 96);
}

.hoverbutton:hover{
  background-color: rgb(248, 0, 128);
  padding: 32px;
  .pageTitle {
    margin-top: 40px;
  }
}

.MenuHyper{
  cursor: pointer;
}

.content {
  background-color:rgba(155, 155, 155, 0.4);
  flex: left;
  margin-top: 3%;
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 25px;
}

.content .subheading {
  align-items: flex-start;
  text-align: center;
}

.content p {
  text-align: center;
}

.content .cv {
  text-align: center;
  align-items: flex-start;
}

.content .cv .cv_subheading {
  text-align: left;
}

.content .cv p {
  display: block;
  word-wrap: break-word;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  width: 900px;
}

.content .cv .cv_left h3 {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 3px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.content .cv .cv_left h4 {
  display: inline-block;
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.content .cv .cv_right h4 {
  display: inline-block;
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.cv_nobullet {
  list-style-type:none;
  display: inline-text;
}

.content .cv .clear{
  clear:both;
}

.content .cv .cv_left {
  float: left;
}

.content .cv .cv_right {
  float: right;
}

.content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.post p {
  text-align: center;
}
.post h3 {
  text-align: center;
}

.spacing hr {
  margin-top:10%;
  padding-top:10%;
  margin-bottom:10%;
  padding-bottom:10%;
}

@media screen and (max-width: 1500px) {
  .content .cv p {
    width: 800px;
  }
} 

@media screen and (max-width: 1366px) {
  .content .cv p {
    width: 650px;
  }
} 

@media screen and (max-width: 1024px) {
  .content .cv p {
    width: 600px;
  }
} 

@media screen and (max-width: 800px) {
  .content .cv p {
    width: 500px;
  }
}

@media screen and (max-width: 720px) {
  .header {
      color: rgb(17, 8, 73);
  }
  .navbar {
    width: 100%;
    display: contents;
  }
  .navbar nav {
    flex: 1;
    align-content: center;
  }
  .content {
    margin-top: 3%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .hoverbutton {
    display: inline-block;
    margin: 8px;
    border-radius: 10%;
    padding: 20px;
  }
  .hoverbutton:hover{
    padding: 35px;
    .pageTitle {
      margin-top: 40px;
    }
  }
  .content .cv .cv_right {
    float: left;
  }
  .content .cv .cv_right .cv_top {
    text-align: left;
    width: 500px;
  }
  .content .cv p {
    width: 450px;
    font-size: 15px;
  }
} 

@media screen and (max-width: 580px) {
  .content .cv p {
    width: 300px;
    font-size: 15px;
  }
}